import React from "react";
import "./Footer.css";
//
import { useNavigate } from "react-router-dom";
//
//import { Outlet, Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
//
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
//import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
//
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import CompanyLogo from "../../assets/logo.png";
import WebIcon from "@mui/icons-material/Language";
//

const Footer = () => {
  //methods
  const navigate = useNavigate();
  const FooterNavigation = (e, path) => {
    e.preventDefault();
    navigate(path, { replace: false });
  };
  return (
    <div className="Footer">
      <div className="FooterTop">
        <div className="FooterTCol1">
          <img className="CompanyLogo" src={CompanyLogo} alt="alt"></img>
          <h3 className="FooterTC1H">Multi Tech Technology Solutions PLC</h3>

          <pre className="FooterTC1PRE">
            Multi-Tech: Your Partner in Cutting-Edge Healthcare Solutions. From
            precise installations to proactive maintenance, we're dedicated to
            advancing patient care globally.
          </pre>
        </div>
        <div className="FooterTCol2">
          <h3 className="FooterTC1H">Company</h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ScrollLink
              to="/"
              spy={true}
              smooth={true}
              offset={-70} // Adjust offset as needed
              duration={500}
            >
              <lable className="FooterTCPRE">Home</lable>
            </ScrollLink>
            <ScrollLink
              to="faq"
              spy={true}
              smooth={true}
              offset={-70} // Adjust offset as needed
              duration={500}
            >
              <lable className="aboutus">About</lable>
            </ScrollLink>
            <ScrollLink
              to="team"
              spy={true}
              smooth={true}
              offset={-70} // Adjust offset as needed
              duration={500}
            >
              <lable className="FooterTCPRE">Team</lable>
            </ScrollLink>
            <ScrollLink
              to="service"
              spy={true}
              smooth={true}
              offset={-70} // Adjust offset as needed
              duration={500}
            >
              <lable className="FooterTCPRE">Service</lable>
            </ScrollLink>
          </div>
        </div>
        <div className="FooterTCol3">
          <h3 className="FooterTC1H">Services</h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              className="FooterTCPRE"
              onClick={(event) => FooterNavigation(event, "/medicalservice")}
            >
              Medical Service & Support
            </label>
            <label
              className="FooterTCPRE"
              onClick={(event) =>
                FooterNavigation(event, "/digitalimagesolution")
              }
            >
              Digital Image Solution
            </label>
            <label
              className="FooterTCPRE"
              onClick={(event) => FooterNavigation(event, "surgicallab")}
            >
              Surgical & LCS
            </label>
            <label
              className="FooterTCPRE"
              onClick={(event) => FooterNavigation(event, "itsoftwaresolution")}
            >
              It & Software Solution
            </label>
            <label
              className="FooterTCPRE"
              onClick={(event) => FooterNavigation(event, "powergrounding")}
            >
              Site Preparation and Consulting Service
            </label>
            {/* <label
              className="FooterTCPRE"
              onClick={(event) => FooterNavigation(event, "hospitalgasservice")}
            >
              Hospital Centarl Gas Station & Utility
            </label> */}
            <label
              className="FooterTCPRE"
              onClick={(event) =>
                FooterNavigation(event, "electromecanicalservice")
              }
            >
              Electro-Mecanical Solutions
            </label>
            <label
              className="FooterTCPRE"
              onClick={(event) => FooterNavigation(event, "labsolutions")}
            >
              Lab Solutions
            </label>
          </div>
        </div>
        <div className="FooterTCol4">
          <h3 className="FooterTC1H">Help</h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {/* <ScrollLink
              to="contact"
              spy={true}
              smooth={true}
              offset={-70} // Adjust offset as needed
              duration={500}
            >
              <lable className="FooterTCPRE">Contact</lable>
            </ScrollLink> */}
            <lable
              className="FooterTCPRE"
              onClick={(event) => FooterNavigation(event, "contact")}
            >
              Contact
            </lable>
            <ScrollLink
              to="faq"
              spy={true}
              smooth={true}
              offset={-70} // Adjust offset as needed
              duration={500}
            >
              <lable className="FooterTCPRE">FAQ</lable>
            </ScrollLink>
          </div>
        </div>
        <div className="FooterTCol5">
          <h3 className="FooterTC1H">Contact</h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>
              <pre className="FooterTC1PRE">
                Megenagna Lem Hotel, in front of Zemen Bank on Negat building,
                Addis Ababa, Ethiopia
              </pre>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <MarkunreadMailboxIcon />{" "}
              <label className="FooterTCPRE">P.O.Box : 19621</label>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CallOutlinedIcon />{" "}
              <label className="FooterTCPRE">Call +2519-23-33-39-31</label>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <EmailOutlinedIcon />
              <label className="FooterTCPRE">info@multitech-et.com</label>
            </div>
          </div>
        </div>
      </div>
      <div className="FooterBottom">
        <h2 className="FooterTC1H">Follow us</h2>
        <div className="FooterSMediaIcons">
          <a href="https:www.facebook.com/">
            <FacebookOutlinedIcon />
          </a>
          <a href="https:www.linkedin.com/">
            <LinkedInIcon />
          </a>
          <a href="https:www.telegram.com/">
            <TelegramIcon />
          </a>
          <a href="https:www.telegram.com/">
            <XIcon />
          </a>
        </div>
      </div>
      <div className="copyrightDiv">
        <div className="copyrightContent">
          <h3>2024,All right reserved</h3>
          <div className="developer">
            <h4>Powered By HabSync Group</h4>{" "}
            <a href="https://www.habsync.com/">
              {" "}
              <WebIcon />
            </a>
          </div>
        </div>

        <div className="floatDIV">
          <ScrollLink
            to="home"
            spy={true}
            smooth={true}
            offset={-70} // Adjust offset as needed
            duration={500}
          >
            <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Fab size="small" color="primary" aria-label="add">
                <ArrowUpwardIcon />
              </Fab>
            </Box>
          </ScrollLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
